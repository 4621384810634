<template>
  <ion-page>
    <ion-header>
      <BaseToolbar :page-title="$t('views.how-to-dyor')" />
    </ion-header>
    <ion-content>
      <BaseContent class="how-to-dyor">
        <h3 class="how-to-dyor__title" v-text="$t('how-to-dyor.title')" />

        <div class="how-to-dyor__wrapper">
          <div class="how-to-dyor__warning">
            <h6 v-text="$t('how-to-dyor.warning')" />
            <p v-text="$t('how-to-dyor.warning-message')" />
          </div>

          <div class="how-to-dyor__disclaimer">
            <p v-text="$t('how-to-dyor.disclaimer-1')" />
            <p v-text="$t('how-to-dyor.disclaimer-2')" />
          </div>

          <div class="how-to-dyor__check-security-audit">
            <p v-text="$t('how-to-dyor.check-security-audit-1')" />
            <p v-text="$t('how-to-dyor.check-security-audit-2')" />
          </div>

          <div class="how-to-dyor__check-contract-address">
            <p v-text="$t('how-to-dyor.check-contract-address-1')" />
            <p v-text="$t('how-to-dyor.check-contract-address-2')" />
          </div>

          <div class="how-to-dyor__important-stuffs">
            <h6 v-text="$t('how-to-dyor.important-stuffs-title')" />
            <ul>
              <li v-text="$t('how-to-dyor.important-stuffs-twitter')" />
              <li v-text="$t('how-to-dyor.important-stuffs-telegram')" />
              <li v-text="$t('how-to-dyor.important-stuffs-bscscan')" />
            </ul>
          </div>

          <div class="how-to-dyor__only-wallets">
            <h6 v-text="$t('how-to-dyor.only-wallets-title')" />
            <ul>
              <li v-text="$t('how-to-dyor.only-wallets-conditions-1')" />
              <li v-text="$t('how-to-dyor.only-wallets-conditions-2')" />
              <li v-text="$t('how-to-dyor.only-wallets-conditions-3')" />
            </ul>
          </div>

          <div class="how-to-dyor__checks">
            <p v-text="$t('how-to-dyor.rug-check')" />
            <p v-text="$t('how-to-dyor.honeypots-check')" />
          </div>

          <div
            class="how-to-dyor__conclusion"
            v-text="$t('how-to-dyor.conclusion')"
          />
        </div>
      </BaseContent>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseToolbar from '@/components/base/BaseToolbar.vue';
import BaseContent from '@/components/base/BaseContent.vue';
import { IonPage, IonContent, IonHeader } from '@ionic/vue';

export default defineComponent({
  name: 'HowToDyor',
  components: {
    BaseToolbar,
    BaseContent,
    IonPage,
    IonContent,
    IonHeader,
  },
});
</script>

<style lang="scss" scoped>
.how-to-dyor {
  &__title {
    text-align: center;
  }

  &__wrapper {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 2em;
    padding: 1em;
  }

  &__warning {
    margin: -1em;
    padding: 1em;
    border-radius: 0.75em;
    background-color: rgba(var(--ion-color-warning-rgb), 0.5);

    p {
      margin: 0;
    }
  }
}
</style>
